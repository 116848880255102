.c-dark-stripe {
  background-color: $navy;
  color: $cloudy-blue;
  position: relative;
  &:before {
    content: ' ';
    background-image: url(../images/navy-wibble.svg);
    background-size: cover;
    width: 112px;
    height: 58px;
    position: absolute;
    left: 0;
    top: -44px;
    @media (min-width: 1024px) {
      width: 287px;
      height: 144px;
      left: 0;
      top: -86px;
    }
  }
  &:after {
    content: ' ';
    background-image: url(../images/orange-claw.svg);
    background-size: cover;
    width: 42px;
    height: 92px;
    position: absolute;
    left: 10px;
    bottom: -46px;
    @media (min-width: 1024px) {
      width: 106px;
      height: 231px;
      left: 100px;
      bottom: -100px;
    }
  }
  &--wrapper {
    padding: 60px 20px 100px;
    @media (min-width: 1360px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 467px;
      width: 80vw;
      max-width: 1500px;
      margin: auto;
    }
  }
  &--title {
    color: $light-teal;
    text-transform: uppercase;
    font-family: Kallisto;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 60px;
    letter-spacing: 5px;
    @media (min-width: 450px) {
      letter-spacing: 11px;
    }
    @media (min-width: 1360px) {
      text-align: left;
      font-size: 55px;
      padding: 0 100px 0;
      white-space: nowrap;
    }
  }
  &--copy {
    color: $cloudy-blue;
    text-align: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 1360px) {
      max-width: 750px;
      text-align: left;
    }
    & ul {
      text-align: left;
      list-style: none;
      & > li {
        position: relative;
        margin-bottom: 28px;
        &:before {
          content: ' ';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: $seafoam-blue;
          position: absolute;
          left: -40px;
          top: 9px;
        }
      }
    }
  }
}
