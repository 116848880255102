blockquote {
  margin: 0;
  padding: 100px 40px 50px;
  height: auto;
  background-color: $navy;
  color: $cloudy-blue;
  position: relative;
  &:before {
    content: ' ';
    width: 46px;
    height: 41px;
    background: url(../images/quote-mark.svg) no-repeat;
    background-size: contain;
    position: absolute;
    left: calc(50% - 23px);
    top: 30px;
    opacity: 0.6;
  }
  & cite {
    font-family: $heading-font-family;
    font-size: 33px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 5.42px;
    text-transform: uppercase;
    color: $light-teal;
  }
}
