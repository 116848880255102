$body-copy-font-family: 'Agenda', sans-serif;
$heading-font-family: 'Kallisto', 'Times New Roman', Times, serif;
$base-font-family: $body-copy-font-family;

$base-font-size: 16;
$base-line-height: 1.5;
$base-spacing-unit: 25px;

$white: #ffffff;
$almost-white: #d4d4d4;
$brownish-grey: #676767;
$pale-grey: #e5eff0;
$cloudy-blue: #d3e2e4;
$light-teal: #81dbdc;
$tomato: #e85314;
$navy: #022249;
$seafoam-blue: #55bcca;
$base-colour: $brownish-grey;
