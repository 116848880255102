.c-steps {
  text-align: center;
  position: relative;
  background: url(../images/light-teal-waves-top-mob.png) no-repeat;
  background-size: contain;
  background-position: bottom left;
  @media (min-width: 450px) {
    background: url(../images/light-teal-waves-top.png) no-repeat;
    background-size: 100%;
    background-position: bottom;
  }
  &:before {
    content: ' ';
    background-image: url(../images/light-teal-blob.svg);
    background-size: cover;
    width: 135px;
    height: 141px;
    position: absolute;
    right: 0;
    top: -175px;
    @media (min-width: 1360px) {
      width: 462px;
      height: 469px;
      right: -150px;
      top: -222px;
    }
    @media (min-width: 1680px) {
      right: 0;
    }
  }

  & h2 {
    margin: 100px 20px 60px;
  }
  &--intro {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 500px;
    margin: 0 auto 60px;
    @media (min-width: 1024px) {
      margin-bottom: 100px;
      max-width: 700px;
    }
  }
  &--wrapper {
    width: 100%;
    max-width: 500px;
    margin: auto;
    @media (min-width: 1024px) {
      width: 80vw;
      margin: auto;
      max-width: 1420px;
    }
  }
  &--step {
    padding-left: 20px;
    padding-right: 20px;
    display: grid;
    padding-bottom: 40px;
    max-width: 500px;
    margin: auto;
    @media (min-width: 1024px) {
      grid-template-columns: 1fr 1fr;
      padding-bottom: 80px;
      max-width: unset;
    }
    &-content {
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;
      @media (min-width: 1024px) {
        padding-left: 100px;
      }
    }
    &:nth-of-type(2):before {
      content: ' ';
      background-image: url(../images/light-teal-claw.svg);
      background-size: contain;
      width: 120px;
      height: 79px;
      position: absolute;
      left: -50px;
      bottom: 20px;
      @media (min-width: 1024px) {
        width: 199px;
        height: 132px;
        left: -50px;
        top: 33%;
        bottom: auto;
      }
    }
    &:nth-of-type(1),
    &:nth-of-type(3) {
      & .c-steps--step-content {
        grid-row: 1;
        @media (min-width: 1024px) {
          grid-row: auto;
        }
      }
      & .c-steps--step-image {
        grid-row: 2;
        @media (min-width: 1024px) {
          grid-row: auto;
        }
      }
    }
    &:nth-of-type(2),
    &:nth-of-type(4) {
      & .c-steps--step-content {
        padding-left: 0;
        z-index: 1;
        text-align: left;
        @media (min-width: 1024px) {
          padding-right: 100px;
        }
      }
    }
    &:last-of-type {
      padding-bottom: 120px;
    }
    &-image {
      width: 100%;
      border-radius: 36px;
    }
    &-pretitle {
      font-family: Kallisto;
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: left;
      color: $tomato;
      margin-bottom: 5px;
      @media (min-width: 1024px) {
        font-size: 25px;
        margin-bottom: 18px;
      }
    }

    &-title {
      font-family: Kallisto;
      font-size: 24px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 5.42px;
      text-transform: uppercase;
      margin-bottom: 20px;
      text-align: left;
      @media (min-width: 1024px) {
        margin-bottom: 50px;
        font-size: 33px;
      }
    }
    &-copy {
      text-align: left;
    }
  }
}
