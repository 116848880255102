.c-content2 {
  background-color: $navy;
  color: $cloudy-blue;
  position: relative;
  overflow: hidden;
  &:before {
    content: ' ';
    background: url(../images/light-teal-claw.svg) no-repeat;
    background-size: contain;
    position: absolute;
    opacity: 0.6;
    left: -50px;
    bottom: 40px;
    width: 120px;
    height: 90px;
    @media (min-width: 1024px) {
      left: -50px;
      bottom: 150px;
      width: 199px;
      height: 132px;
    }
  }
  &:after {
    content: ' ';
    background: url(../images/light-teal-blob.svg) no-repeat;
    background-size: contain;
    position: absolute;
    overflow: hidden;
    opacity: 0.4;
    width: 130px;
    height: 130px;
    right: -15px;
    top: -22px;
    @media (min-width: 1024px) {
      right: -74px;
      bottom: 150px;
      width: 434px;
      height: 365px;
    }
  }
  #newsletter & {
    display: none;
  }
  &--wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: auto;
    width: 90vw;
    max-width: 750px;
    margin: auto;
  }
  &--copy {
    color: $cloudy-blue;
    margin-bottom: 120px;
    margin-top: 120px;
    text-align: center;
    @media (min-width: 450px) and (max-width: 1024px) {
      padding: 0;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
    & h2,
    & h3,
    & h4 {
      text-align: center;
      color: $light-teal;
      margin-top: 0;
      margin-bottom: 60px;
    }
    & ul {
      text-align: left;
      list-style: none;
      & > li {
        position: relative;
        margin-bottom: 28px;
        &:before {
          content: ' ';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: $seafoam-blue;
          position: absolute;
          left: -40px;
          top: 9px;
        }
      }
    }
  }
}
