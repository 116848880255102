.cta {
  padding: 10px 18px;
  border: 2px solid $tomato;
  border-radius: 11px;
  background-color: $tomato;
  color: $white;
  transition: all 0.15s ease-in;
  @media (min-width: 768px) {
    padding: 10px 25px;
  }
  &:after {
    display: none;
  }
  &:hover,
  :focus,
  :active {
    background-color: $white;
    color: $tomato;
  }
  &.cta-secondary {
    background-color: $white;
    color: $tomato;
    &:hover,
    :focus,
    :active {
      background-color: $tomato;
      color: $white;
    }
  }
}
