@import '../config/variables';
//paragraph font sizes
$min_width: 375px;
$max_width: 1920px;
$p_min_font: 16px;
$p_max_font: 20px;

$p2_min_font: 19px;
$p2_max_font: 24px;

$h1_min_font: 36px;
$h1_max_font: 67px;

$h2_min_font: 30px;
$h2_max_font: 50px;

$h3_min_font: 20px;
$h3_max_font: 33px;

$h4_min_font: 24px;
$h4_max_font: 28px;

html * {
  font-family: $base-font-family;
  @include fluid-type($min_width, $max_width, $p_min_font, $p_max_font);
}

body {
  background-color: $pale-grey;
  color: $base-colour;
  font-family: $base-font-family;
  font-weight: 400;
  line-height: $base-line-height;
  text-size-adjust: none;
  @include font-size($base-font-size + 2);
  & p {
    margin-top: 0;
  }
}
.h1,
h1 {
  @include fluid-type($min_width, $max_width, $h1_min_font, $h1_max_font);
  text-transform: uppercase;
  font-family: $heading-font-family;
  letter-spacing: 5px;
  @media (min-width: 450px) {
    letter-spacing: 11px;
  }
}
.h2,
h2 {
  @include fluid-type($min_width, $max_width, $h2_min_font, $h2_max_font);
  text-transform: uppercase;
  font-family: $heading-font-family;
  letter-spacing: 5px;
  @media (min-width: 450px) {
    letter-spacing: 11px;
  }
}
.h3,
h3 {
  @include fluid-type($min_width, $max_width, $h3_min_font, $h3_max_font);
  text-transform: uppercase;
  font-family: $heading-font-family;
  letter-spacing: 5px;
  @media (min-width: 450px) {
    letter-spacing: 11px;
  }
}
.h4,
h4 {
  @include fluid-type($min_width, $max_width, $h4_min_font, $h4_max_font);
  text-transform: uppercase;
}
.quote__item__text,
.section--p-large {
  p strong,
  li,
  p,
  p a {
    @include fluid-type($min_width, $max_width, $p2_min_font, $p2_max_font);
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $navy;
}

a {
  color: $tomato;
  text-decoration: none;
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 0;
    background-color: $tomato;
    transition: width 0.15s ease-in;
  }
  &:hover {
    &:after {
      width: 100%;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
hr,
ul,
ol,
dl {
  margin-bottom: $base-spacing-unit;
  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * Accessibility
 */

.sr-only,
.screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.overflowKiller {
  overflow: hidden;
}
.fixedPosition {
  position: fixed;
  width: 100vw;
  height: 100vw;
}

sup {
  top: -0.8em;
  font-size: 0.5em !important;
}
