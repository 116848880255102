.pre-footer {
  background: url(../images/light-teal-waves-bottom-mob.png) no-repeat;
  background-size: contain;
  background-position: top left;
  padding: 0 20px;
  overflow: hidden;
  @media (min-width: 450px) {
    background: url(../images/light-teal-waves-bottom.png) no-repeat;
    background-size: contain;
    background-position: top;
  }
  @media (min-width: 1280px) {
    background-size: cover;
    height: 300px;
    padding: 0;
  }
  #newsletter & {
    display: none;
  }
  &--wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    @media (min-width: 1360px) {
      flex-direction: row;
      max-width: 1200px;
      padding-left: 40px;
      padding-right: 40px;
      margin-left: auto;
      margin-right: auto;
    }
    @media (min-width: 1680px) {
      max-width: 1500px;
    }
  }

  &--newsletter {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 1280px) {
      flex-direction: row;
      padding-right: 60px;
      //padding-left: 60px;
    }
    &-intro {
      font-family: Kallisto;
      font-size: 24px;
      font-weight: bold;
      margin-top: -5px;
      color: $navy;
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
      @media (min-width: 450px) {
        margin-top: 35px;
        margin-bottom: 35px;
      }
      @media (min-width: 1280px) {
        margin-right: 40px;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 28px;
        text-align: left;
      }
      @media (min-width: 1400px) {
        margin-right: 60px;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 32px;
        text-align: left;
      }
      & br {
        display: none;
        @media (min-width: 450px) {
          display: inline;
        }
      }
    }
    & form {
      display: grid;
      grid-template-columns: 1fr 119px;
      position: relative;
      border-radius: 11px;
      background-color: $white;
      @media (min-width: 768px) {
        grid-template-columns: 1fr 177px;
      }
      &:before {
        content: 'Sign up to the newsletter';
        position: absolute;
        left: 10px;
        top: 50%;
        width: 100px;
        top: 11px;
        left: 16px;
        @media (min-width: 768px) {
          width: 200px;
          top: 30px;
          left: 25px;
        }
        @media (min-width: 1680px) {
          width: 230px;
        }
      }
      &:focus-within:before,
      &.hasEmail:before {
        display: none;
      }
      & input[type='text'] {
        background-color: transparent;
        width: 100%;
        height: 64px;
        vertical-align: bottom;
        border: 1px solid $almost-white;
        border-right: none;
        border-radius: 11px 0 0 11px;
        padding: 0 10px;
        z-index: 2;
        @media (min-width: 360px) {
          //width: calc(100% - 24px);
        }
        @media (min-width: 768px) {
          height: 85px;
          width: 340px;
        }
        @media (min-width: 1280px) {
          width: 240px;

          padding: 0 24px;
        }
        @media (min-width: 1400px) {
          width: 340px;
          height: 85px;
          padding: 0 24px;
        }
        @media (max-width: 767px) {
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            white-space: pre-line;
            position: relative;
            top: -11px;
            padding-left: 10px;
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            white-space: pre-line;
            position: relative;
            top: -11px;
            padding-left: 10px;
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            white-space: pre-line;
            position: relative;
            top: -11px;
            padding-left: 10px;
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            white-space: pre-line;
            position: relative;
            top: -11px;
            padding-left: 10px;
          }
        }
      }
      & input[type='submit'] {
        background-color: $tomato;
        color: $white;
        font-family: Kallisto;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        width: 119px;
        height: 66px;
        vertical-align: bottom;
        border: 3px solid $tomato;
        border-radius: 0 11px 11px 0;
        //margin-left: -5px;
        transition: all 0.15s ease-in;
        z-index: 2;
        @media (min-width: 768px) {
          font-size: 20px;
          width: 177px;
          height: 85px;
        }
        &:hover {
          background-color: $white;
          color: $tomato;
        }
      }
    }
  }
  &--social {
    display: grid;
    grid-template-columns: 37px 37px 37px;
    grid-gap: 20px;
    margin: 30px auto;
    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 40px;
      align-content: center;
    }
    & a {
      &:hover {
        svg path:first-of-type {
          fill: $tomato;
        }
      }
      &:after {
        display: none;
      }
      & svg {
        width: 37px;
        @media (min-width: 768px) {
          width: auto;
        }
        & path:first-of-type {
          fill: $navy;
          transition: all 0.15s ease-in;
        }
      }
    }
  }
}

.footer {
  background-color: $navy;
  @media (min-width: 1280px) {
    height: 155px;
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    @media (min-width: 1280px) {
      flex-direction: row;
      justify-content: space-between;
      margin-left: 100px;
      margin-right: 100px;
    }
  }
  &-copyright {
    margin: 35px 0 45px;
    @media (min-width: 1280px) {
      margin: 0;
    }
    color: $light-teal;
  }
  &-links {
    display: flex;
    flex-direction: column;
    text-align: center;
    @media (min-width: 1280px) {
      display: block;
      text-align: left;
    }
    & a {
      color: $light-teal;
      text-decoration: none;
      position: relative;
      margin-bottom: 20px;
      @media (min-width: 1280px) {
        margin-left: 80px;
        margin-bottom: 0;
      }
      &:after {
        content: ' ';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 0;
        background-color: $light-teal;
        transition: width 0.15s ease-in;
      }
      &:hover {
        &:after {
          width: 100%;
        }
      }
      &.wearenorth {
        margin-top: 20px;
        margin-bottom: 60px;

		img {
			height: 24px;
		}

		@media (min-width: 1280px) {
          margin-top: 0;
        }
        &:after {
          display: none;
        }

      }
    }
  }
}

.sub-footer {
  padding: 15px 30px;
  display: flex;
  justify-content: center;
}
