@use "sass:math";
/*
 * Media query to respond to a minimum size (mobile first)
 */

@mixin resp-min($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}

/*
 * Media query to respond to a maximum size
 */

@mixin resp-max($size) {
  @media screen and (max-width: $size) {
    @content;
  }
}

/*
 * Output font size in px/rem
 */

@mixin font-size($px) {
  font-size: $px + px;
  font-size: math.div($px, 16) rem;
}

/*
 * Placeholder mixin for <input>
 */

@mixin placeholder {
  $placeholders: ':-webkit-input' ':-moz' '-moz' '-ms-input';
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}

/**
 * Clearfix
 */
@mixin clearfix {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }
}

@function strip-unit($value) {
  @return math.div($value, $value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}
