header {
  position: relative;
  background-image: url('../images/hero-bg-mob.svg');
  background-size: cover;
  background-position: bottom;
  height: 95vh;
  min-height: 500px;
  max-height: 900px;
  width: 100%;
  @media (min-width: 450px) {
    min-height: 750px;
    background-image: url('../images/hero-bg.svg');
  }

  &.hasExtendedCopy {
    min-height: 95vh;
    max-height: 10000px;
    height: auto;
    & .c-hero {
      position: unset;
      &--intro {
        margin-top: 150px;
        @media (min-width: 1024px) {
          margin-top: 300px;
        }
      }
    }
  }
  & nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    z-index: 1;
    @media (min-width: 1024px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: absolute;
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-right: 20px;
    }
    @media (min-width: 1280px) {
      width: calc(100% - 200px);
      margin-left: 100px;
      margin-right: 100px;
    }

    & .c-navbar {
      margin-top: 17px;
      margin-right: 24px;
      @media (min-width: 1024px) {
        margin-top: 84px;
      }
      &--button {
        content: '';
        width: 32px;
        height: 4px;
        background-color: $navy;
        border-radius: 2px;
        border: none;
        position: absolute;
        right: 25px;
        top: 30px;
        transition: all 0.15s ease-in;
        @media (min-width: 1024px) {
          display: none;
        }
        &:after,
        &:before {
          content: '';
          width: 32px;
          height: 4px;
          background-color: $navy;
          border-radius: 2px;
          border: none;
          position: relative;
          transform: rotate(0deg);
          transition: all 0.3s ease-in;
        }
        &:after {
          position: absolute;
          left: 0;
          top: -9px;
          transform-origin: bottom right;
        }
        &:before {
          position: absolute;
          left: 0;
          bottom: -9px;
          transform-origin: top right;
        }
        &.opened {
          z-index: 6;
          background-color: transparent;
          &:after {
            z-index: 6;
            background-color: $light-teal;
            top: -12px;
            transform: rotate(-45deg);
          }
          &:before {
            z-index: 6;
            background-color: $light-teal;
            bottom: -12px;
            transform: rotate(45deg);
          }
        }
      }
      .c-navbar--items {
        display: flex;
        flex-direction: column;
        align-items: center;
        visibility: hidden;
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        padding-top: 125px;
        background: transparent;
        z-index: 5;
        transition: all 0.15s;
        transition-delay: 0.5s;
        @media (min-width: 1024px) {
          visibility: visible;
          display: block;
          position: relative;
          width: 700px;
          height: auto;
          padding-top: 0;
        }
        &.opened {
          visibility: visible;
          transition-delay: 0;
          transition: none;
          & a,
          & > span {
            opacity: 1;
          }
        }
        & a,
        & > span {
          color: $light-teal;
          text-decoration: none;
          font-size: 25px;
          margin: 0px 15px;
          padding: 0 16px 3px;
          position: relative;
          padding-top: 10px;
          padding-bottom: 10px;
          opacity: 0;
          transition: all 0.3s cubic-bezier(0, 0.995, 0.99, 1) 0.4s;
          @media (min-width: 1024px) {
            opacity: 1;
            color: $navy;
            padding: 0 3px;
            margin: 10px 25px;
            transition: all 0.15s ease-in-out;
            &.home {
              display: none;
            }
          }

          &:after {
            display: none;
          }
          &:hover {
            @media (min-width: 768px) {
              color: $white;
              & .hover-background {
                right: 0;
              }
            }
          }
        }
        & > span.current {
          color: $white;
        }
      }
      &--splash {
        position: absolute;
        top: 40px;
        right: 40px;
        width: 1px;
        height: 1px;
        &:after {
          content: '';
          display: block;
          position: absolute;
          border-radius: 50%;
          background-color: $navy;
          width: 284vmax;
          height: 284vmax;
          top: -142vmax;
          left: -142vmax;
          transform: scale(0);
          transform-origin: 50% 50%;
          transition: transform 0.5s cubic-bezier(0.755, 0.05, 0.855, 0.06);
          will-change: transform;
        }
        &.opened:after {
          transform: scale(1);
        }
      }
      & .hover-background {
        position: absolute;
        top: 0;
        right: 100%;
        bottom: 0;
        left: 0;
        background-color: $tomato;
        transition: all 0.15s ease-in;
        z-index: -1;
      }
    }
    & .current {
      color: $white;
      background-color: $tomato;
      text-decoration: none;
      cursor: default;
      font-size: 25px;
      margin: 10px 25px;
      padding: 0 3px 3px;
      position: relative;
      opacity: 0;
      transition: all 0.3s cubic-bezier(0, 0.995, 0.99, 1) 0.4s;
    }
  }
  & .c-hero {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    &--intro {
      width: 750px;
      margin: auto;
      text-align: center;
      &-heading {
        font-family: Kallisto;
        font-size: 24px;
        font-weight: bold;
        position: relative;
        margin-bottom: 30px;
        margin-top: 60px;
        letter-spacing: 5px;
        @media (min-width: 360px) {
          font-size: 32px;
          margin-bottom: 60px;
          margin-top: 20px;
        }
        @media (min-width: 450px) {
          letter-spacing: 11px;
        }
        @media (min-width: 1024px) {
          font-size: 55px;
        }

        &:after {
          position: absolute;
          bottom: 0px;
          left: calc(50% - 30px);
          content: '';
          width: 59px;
          height: 3px;
          background-color: $tomato;
        }
      }
      &-copy {
        font-size: 1.3em !important;
        padding-left: 20px;
        padding-right: 20px;
        @media (min-width: 450px) and (max-width: 1024px) {
          padding: 0;
          max-width: 500px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      margin-left: auto;
      margin-right: auto;
      &-copy2 {
        padding-left: 20px;
        padding-right: 20px;
        margin: 60px 0 90px;
        max-width: calc(100vw - 40px);
        @media (min-width: 450px) and (max-width: 1024px) {
          padding: 0;
          max-width: 500px;
          margin-left: auto;
          margin-right: auto;
        }
        & h2,
        & h3,
        & h4 {
          margin-bottom: 60px;
        }
        & ul {
          text-align: left;
          list-style: none;
          & > li {
            position: relative;
            margin-bottom: 28px;
            &:before {
              content: ' ';
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: $seafoam-blue;
              position: absolute;
              left: -40px;
              top: 9px;
            }
          }
        }
      }
    }
  }

  & .c-site-logo {
    &:after {
      display: none;
    }
    & img {
      margin-top: 20px;
      margin-left: 20px;
      height: 27px;
      @media (min-width: 1024px) {
        margin-top: 72px;
        height: 45px;
      }
    }
  }
}
