.modalNotice {
  visibility: none;
  position: fixed;
  top: 30px;
  left: 50%;
  margin-left: calc(-45vw + 15px);
  width: calc(90vw - 30px);
  background-color: $white;
  border: 15px solid $seafoam-blue;
  border-radius: 20px;
  z-index: 300;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  @media (min-width: 768px) {
    width: 500px;
    margin-left: -250px;
  }
  &.open {
    visibility: visible;
    opacity: 1;
  }
  & .modalNotice-content {
    padding: 20px;
  }
  & .close-button {
    cursor: pointer;
  }
}

.darkLayer {
  visibility: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  &.visible {
    visibility: visible;
    z-index: 5;
    opacity: 1;
  }
}
